import React from "react"
import { graphql } from "gatsby"
import Container from "../../components/common/Container"
import GridTwo from "../../components/common/GridTwo"
import GridThree from "../../components/common/GridThree"
import CardPrint from "../../components/common/CardPrint"
import Layout from "../../components/layouts/Default"
import { H2 } from "../../components/typography/Headings"
import P from "../../components/typography/P"
import PageHeader from "../../components/common/PageHeader"
import Breadcrumb from "../../components/common/Breadcrumb"

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Zeitschriften" desc="">
      <PageHeader title="Zeitschriften" image={data.header} />
      <Breadcrumb pages={[ { name: "Verlag", to: "/verlag/" },{ name: "Zeitschriften", to: "/verlag/zeitschriften/" }]} />

      <Container className="space-y-8 md:space-y-16">
        <div>
          <div className="mb-8 md:mb-16">
            <H2 className="mb-5 font-display">Zeitschriften</H2>
            <GridTwo>
              <div className="space-y-5">
                <P>
                  Wir von NATURBLICK behaupten: Egoismus in Sachen Natur ist
                  gut. Unser Ziel ist es, diesen Egoismus in unseren Lesern zu
                  wecken. Wir verführen sie, die Schönheiten der Natur für sich
                  zu gewinnen. Auch solche, die sich im vermeintlich Hässlichen
                  verstecken. Denn wer mit fotografischem Blick auf
                  Schönheitssuche geht, lernt das Staunen und stellt fest: Die
                  Natur feiert eine wunderbare Premiere.
                </P>
                <P>Und das an jedem Tag!</P>
                <P>
                  Staunende Gäste dieser Premiere bringen es nicht fertig, der
                  Natur zu schaden. Sie helfen, die Natur zu bewahren. So bauen
                  sie Brücken für die Zukunft.{" "}
                </P>
                <P>
                  NATURBLICK versteht sich als Forum und wendet sich an
                  naturverbundene Profifotografen. Sie legen Wert darauf, ihre
                  Erfahrungen preiszugeben. So helfen sie begeisterten
                  Amateuren, auf Profiwegen erfolgreich zu werden.{" "}
                </P>
              </div>
              <div className="space-y-5">
                <P>
                  <strong>NATURBLICK bietet mehr:</strong>
                </P>
                <ul className="pl-3 space-y-3 list-disc">
                  <li className="list-item">
                    Wissenswertes zum technischen Equipment, Tipps in sämtlichen
                    Bereichen zwischen Makro- und Panoramafotografie
                  </li>
                  <li className="list-item">
                    Informationen über Veranstaltungen und Workshops
                  </li>
                  <li className="list-item">
                    Organisation von Fotoexkursionen und -workshops
                  </li>
                </ul>
                <P>
                  Sie können das NATURBLICK-Magazin auf verschiedene Weise
                  beziehen: im Abo oder als Geschenk-Abo. Einzelne Hefte können
                  Sie über den Bestellschein erhalten. NATURBLICK können Sie
                  auch im Zeitschriftenhandel – bundesweit an über 900
                  Verkaufsstellen und in Österreich und der Schweiz – erhalten.
                  Nachbestellungen von Einzelausgaben sind nur direkt beim
                  Verlag möglich.
                </P>
              </div>
            </GridTwo>
          </div>
          <GridThree>
            {data.zeitschriften.nodes.map((pub, i) => {
              return (
                <CardPrint
                  image={pub.remoteCoverImage}
                  title={pub.title}
                  link={`/verlag/zeitschriften/${pub.title_slug}/`}
                  key={i}
                />
              )
            })}
          </GridThree>
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    header: file(relativePath: { eq: "pages/verlag/header.jpg" }) {
      childImageSharp {
        gatsbyImageData(transformOptions: {cropFocus: ATTENTION}, width: 1600)
      }
    }
    zeitschriften: allPublikationen(
      sort: { fields: date, order: DESC }
      filter: { art: { eq: "Zeitschriften" } }
    ) {
      nodes {
        remoteCoverImage {
          childImageSharp {
            gatsbyImageData(transformOptions: {cropFocus: ATTENTION}, width: 1024)
          }
        }
        title
        title_slug
      }
    }
  }
`
